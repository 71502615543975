
import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Name',
          value: 'translatableName',
        },
        {
          text: 'Institution',
          value: '_count.EventInstitutionGroupMember',
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'EventInstitutionGroupMember',
        label:  'Group Members',
      },
      {
        key: 'Info',
        label:  i18n.t("string.info"),
      },

    ],
    table: {
      headers: [
        {
          text: 'Name',
          value: 'translatableName',
        },
        {
          text: 'Key',
          value: 'key',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type:'string',
        key:'translatableName',
        name:'translatableName',
         defaultValue:'{"en":"Example","zh":"例子","ms":"Contoh"}'
      },
      {
        type:'string',
        key:'key',
        name:'Key',
      },
    ],
    rules: {
      translatableName: [v => !!v || 'Translatable name is required'],
      key: [v => !!v || 'Key is required'],
    },
  },
  edit: true,
  delete: true,
}