import Moderator from '@/models/bread/Moderator/sponsorshipPackage'

export default {
  key:'SponsorshipPackage',
  server:'sso',
  name:{
    singular:'SponsorshipPackage',
    plural:'SponsorshipPackages',
  },
  parents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}