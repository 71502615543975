
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  'Model',
          value: 'model',
        },
        {
          text:  'Action',
          value: 'action',
        },
        {
          text: "Created",
          value: "timestampCreated",
          filterable: false,
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:true,
}