import i18n from "@/plugins/i18n";
import moment from "moment";

export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: "Name",
          value: "Student",
        },
        {
          text: "Institution",
          value: "Institution",
        },
        {
          text: "Status",
          value: "sponsoredStudentstatus",
        },
        {
          text: "Start Date",
          value: "dateStart",
        },
        {
          text: "End Date",
          value: "dateEnd",
        },
        {
          text: "Applied Reason",
          value: "appliedShortReason",
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: "Institution",
          value: "Institution",
        },
        {
          text: "Classroom",
          value: "Student.Classroom.name",
        },
        {
          text: "Name",
          value: "Student",
        },
        {
          text: "Avatar",
          value: "avatar",
        },
        {
          text: "Level",
          value: "level",
        },
        {
          text: "Plan",
          value: "Plan",
        },
        {
          text: "Start Date",
          value: "dateStart",
        },
        {
          text: "End Date",
          value: "dateEnd",
        },
        {
          text: "Status",
          value: "sponsoredStudentstatus",
        },
        {
          text: "Remark",
          value: "remark",
        },
        {
          text: 'Applied At',
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: "Applied By",
          value: "sponsoredStudentAppliedBy",
        },
        {
          text: "Applied Contact",
          value: "appliedByMobile",
        },
        {
          text: "Applied Reason",
          value: "appliedReason",
        },
        {
          text: "Rejected At",
          value: "timestampRejected",
        },
        {
          text: "Rejected By",
          value: "rejectedByModeratorName",
        },
        {
          text: "Rejected Reason",
          value: "rejectedReason",
        },
        {
          text: "Terminated At",
          value: "timestampCancelled",
        },
        {
          text: "Terminated By",
          value: "cancelledByUserName",
        },
        {
          text: "Terminated Reason",
          value: "terminatedReason",
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: true,
  approve: {
    fields: [
      {
        type: "model",
        key: "sponsorshipId",
        name: "Sponsorship",
        model: "sponsorship",
        optionText: "name",
        server: "sso",
        optionValue: "id",
        defaultValue: 0,
      },
      {
        type: "date",
        key: "dateStart",
        name: "Date Start",
        min: moment().format("YYYY-MM-DD"),
        defaultValue: null,
      },
      // {
      //   type: "time",
      //   key: "timeStart",
      //   name: "Time Start",
      //   defaultValue: null,
      // },
      {
        type: "date",
        key: "dateEnd",
        name: "Date End",
        min: moment().format("YYYY-MM-DD"),
        defaultValue: "",
      },
      // {
      //   type: "time",
      //   key: "timeEnd",
      //   name: "Time End",
      //   defaultValue: null,
      // },
      {
        type: "string",
        key: "remark",
        name: "Remark",
      },
    ],
    rules: {},
  },
  edit: {
    fields: [
      {
        type: "model",
        key: "sponsorshipId",
        name: "Sponsorship",
        model: "sponsorship",
        optionText: "name",
        server: "sso",
        optionValue: "id",
        defaultValue: 0,
      },
      {
        type: "string",
        key: "remark",
        name: "Remark",
      },
      {
        type: "string",
        key: "rejectedReason",
        name: "Rejected Reason",
      },
      {
        type: "string",
        key: "terminatedReason",
        name: "Terminated Reason",
      },
      {
        type: "date",
        key: "dateStart",
        name: "Date Start",
        min: moment().format("YYYY-MM-DD"),
        defaultValue: null,
      },
      // {
      //   type: "time",
      //   key: "timeStart",
      //   name: "Time Start",
      //   defaultValue: null,
      // },
      {
        type: "date",
        key: "dateEnd",
        name: "Date End",
        min: moment().format("YYYY-MM-DD"),
        defaultValue: "",
      },
      // {
      //   type: "time",
      //   key: "timeEnd",
      //   name: "Time End",
      //   defaultValue: null,
      // },
    ],
    rules: {
      
    },
  },
  delete: false,
};
