import Moderator from '@/models/bread/Moderator/eventInstitutionGroupMember'

export default {
    key: 'EventInstitutionGroupMember',
    name: {
        singular: 'EventInstitutionGroupMember',
        plural: 'EventInstitutionGroupMembers',
    },
    parents: [
        'EventInstitutionGroup',
    ],
    grandParents:[
      'Event',
    ],
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}