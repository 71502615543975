import Moderator from '@/models/bread/Moderator/sponsorSponsorship'

export default {
  key:'Sponsorship',
  name:{
    singular:'Sponsorship',
    plural:'Sponsorship',
  },
  parents:[
    'InstitutionSponsor',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}