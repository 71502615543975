import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
        text: i18n.t("model.prop.no"),
        value: 'index',
        sortable: false,
      },
        {
          text: "Name",
          value: 'name',
        },
        {
          text: "Company Name",
          value: 'companyName',
        },
        {
          text: "Mobile",
          value: 'mobile',
        },
        {
          text: "Email",
          value: 'email',
        },
        {
          text: "Sponsorship",
          value: 'sponsorshipCount',
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Sponsorship',
        label: 'Sponsorship',
      },
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.logo"),
          value: 'companylogoUrl',
        },
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: "Company Name",
          value: 'companyName',
        },
        {
          text: "Position",
          value: 'position',
        },
        {
          text: "Mobile",
          value: 'mobile',
        },
        {
          text: "Email",
          value: 'email',
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'string',
        key: 'name',
        name: 'Name',
      },
      {
        type: 'string',
        key: 'position',
        name: 'Position',
      },
      {
        type: 'string',
        key: 'companyName',
        name: 'Company Name',
      },
      {
        type: 'string',
        key: 'mobile',
        name: 'Mobile',
      },
      {
        type: 'string',
        key: 'email',
        name: 'Email',
      },
    ],
    rules: {
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  edit: true,
  delete: true,
}