import i18n from "@/plugins/i18n";
import moment from 'moment'

export default {
  browse: {
    table: {
      headers: [
        {
          text: "Sponsor",
          value: "Sponsor",
        },
        {
          text: "Sponsorship Package",
          value: "SponsorshipPackage",
        },
        {
          text: "Seats",
          value: "SponsorshipPackage.seat",
        },
        {
          text: "Used Seats",
          value: "sponsoredStudentCount",
        },
        {
          text: "Start Date",
          value: "dateStart",
        },
        {
          text: "End Date",
          value: "dateEnd",
        },
      ],
    },
  },
  read: {
    tabs: [
      // {
      //   key: "Institution",
      //   label: 'Institution',
      // },
      {
        key: "SponsoredStudent",
        label: "Sponsored Student",
      },
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: "Sponsor",
          value: "Sponsor",
        },
        {
          text: "Sponsorship Package",
          value: "SponsorshipPackage",
        },
        {
          text: "Start Date",
          value: "dateStart",
        },
        {
          text: "End Date",
          value: "dateEnd",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
   add: {
     fields: [
      {
        type: 'model',
        key: 'sponsorId',
        name: 'Sponsor',
        model: 'sponsor',
        optionText: 'name',
        server:'sso',
        optionValue: 'id',
      },
       {
         type: 'model',
         key: 'sponsorshipPackageKey',
         name: 'Sponsorship Package',
         model: 'sponsorshipPackage',
         optionText: 'name',
         server:'sso',
         optionValue: 'key',
       },
       {
         type: "date",
         key: "dateStart",
         name: "Date Start",
         min: moment().format("YYYY-MM-DD"),
         defaultValue: moment().format("YYYY-MM-DD"),
       },
      //  {
      //    type: "time",
      //    key: "timeStart",
      //    name: "Time Start",
      //    defaultValue: "24:00",
      //  },
       {
         type: "date",
         key: "dateEnd",
         name: "Date End",
         min: moment().format("YYYY-MM-DD"),
         defaultValue: moment().add(1, "years").format("YYYY-MM-DD"),
       },
      //  {
      //    type: "time",
      //    key: "timeEnd",
      //    name: "Time End",
      //    defaultValue: "23:59",
      //  },
     ],
     rules: {
       sponsorshipPackageKey: [(v) => !!v || 'Sponsorship Package Key required'],
     },
   },
  edit: false,
  delete: false,
};
