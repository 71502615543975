
import Moderator from '@/models/bread/Moderator/playerActivity'


export default {
  key:'Activity',
  name:{
    singular:'Activity',
    plural:'Activities',
  },
  parents:[
    'Player',
  ],
  grandParents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}