import Moderator from '@/models/bread/Moderator/sponsoredStudent'

export default {
  key:'SponsoredStudent',
  server:'sso',
  name:{
    singular:'SponsoredStudent',
    plural:'SponsoredStudents',
  },
  parents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}