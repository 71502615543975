import Moderator from '@/models/bread/Moderator/achievementLevel'

export default {
  key:'AchievementLevel',
  server:'game',
  name:{
    singular:'AchievementLevel',
    plural:'AchievementLevels',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}