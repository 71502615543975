export default [
  {
    id:1,
    name:'Not Sure',
    value:null
  },
  {
    id:2,
    name:'Male',
    value:'male'
  },
  {
    id:3,
    name:'Female',
    value:'female'
  },
  {
    id:4,
    name:'Other',
    value:'other'
  },
]