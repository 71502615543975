import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Name",
          value: "translatableName",
        },
        {
          text: "Image",
          value: "levelImage",
        },
        {
          text: "Key",
          value: "key",
        },
        {
          text: "Achievement Level Reward",
          value: "achievementLevelReward",
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: "Image",
          value: "imageUrl",
        },
        {
          text: "Name",
          value: "translatableName",
        },
        {
          text: "Key",
          value: "key",
        },
        {
          text: "Achievement Level Reward",
          value: "achievementLevelReward",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type:'string',
        key:'translatableName',
        name:'Level Name',
        defaultValue:'{"en":"Example","zh":"例子","ms":"Contoh"}'
      },
      {
        type:'string',
        key:'translatableDescription',
        name:'Description',
        defaultValue:'{"en":"Example","zh":"例子","ms":"Contoh"}'
      },
      {
        type:'string',
        key:'key',
        name:'Key',
      },
      {
        type: "number",
        key: "milestone",
        name: "Milestone",
        min: 0,
        defaultValue: 0,
      },
      {
        type: "number",
        key: "level",
        name: "Level",
        min: 0,
        defaultValue: 0,
      },
    ],
    rules: {
      translatableName: [v => !!v || 'Achievement level name is required'],
      key: [v => !!v || 'Achievement level key is required'],
    },
  },
  edit: true,
  delete: true,
};
