export default [
  {
    id:1,
    name:'Not Sure',
    value:null
  },
  {
    id:2,
    name:'Chinese',
    value:'chinese'
  },
  {
    id:3,
    name:'Malay',
    value:'malay'
  },
  {
    id:4,
    name:'Indian',
    value:'indian'
  },
  {
    id:5,
    name:'Other',
    value:'other'
  },
]