import moment from 'moment'
import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.name.series"),
          value: 'Series.name',
        },
        // {
        //   text: 'Code',
        //   value: 'code',
        // },
        // {
        //   text: 'Checkpoints',
        //   value: 'countCheckpoint',
        // },
        // {
        //   text: 'Participants',
        //   value: 'countParticipant',
        // },
        // {
        //   text: 'Prizes',
        //   value: 'countEventPrize',
        // },
        // {
        //   text: 'Classrooms',
        //   value: 'countClassroomOnEvent',
        // },
        {
          text: i18n.t("model.prop.start"),
          value: 'timestampStart',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.end"),
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.status"),
          value: 'eventStatus',
          filterable: false,
        },
        // {
        //   text: '',
        //   value: 'scoreboard',
        //   filterable: false,
        // },
      ],
    },
  },
  read: {
    tabs: [
      // {
      //   key: 'ClassroomOnEvent',
      //   label: i18n.t("model.name.classrooms"),
      // },
      {
        key: 'EventSchoolYear',
        label:  'School Year',
      },
       {
        key: 'EventSponsor',
        label:  i18n.t("model.name.sponsors"),
      },
      {
        key: 'EventPlayer',
        label:  i18n.t("model.name.players"),
      },
      // {
      //   key: 'EventPrize',
      //   label:  i18n.t("model.name.prizes"),
      // },
      {
        key: 'EventRule',
        label:  'Rules',
      },
      {
        key: 'EventFinisher',
        label:  'Finisher',
      },
      {
        key: 'DeliveryInfo',
        label:  'Delivery Info',
      },
      {
        key: 'EventReport',
        label:  'Report',
      },
      {
        key: 'EventInstitutionGroup',
        label:  'Institution Group',
      },
      {
        key: 'Info',
        label:  i18n.t("string.info"),
      },

    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.name.series"),
          value: 'Series.name',
        },
        {
          text:  'Game Code',
          value: 'gameCode',
        },
        {
          text: i18n.t("model.prop.code"),
          value: 'code',
        },
       
        {
          text:  i18n.t("view.PageBackendEvent.notice"),
          value: 'eventMessageNotice',
        },
        // {
        //   text:  i18n.t("view.PageBackendEvent.pass"),
        //   value: 'eventPass',
        // },
        // {
        //   text:  i18n.t("view.PageBackendEvent.invitation"),
        //   value: 'eventMessageInvitation',
        // },
        // {
        //   text: i18n.t("view.PageBackendEvent.scoreboard"),
        //   value: 'eventMessageScoreboard',
        // },
        {
          text:  i18n.t("model.prop.start"),
          value: 'timestampStart',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.end"),
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.status"),
          value: 'eventStatus',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [{
        type: 'model',
        key: 'gameId',
        name: 'Game',
        model: 'game',
        defaultValue: 1,
      },
      {
        type: 'model',
        key: 'syllabusId',
        name: 'Syllabus',
        model: 'syllabus',
        defaultValue: 1,
      },
      {
        type: 'string',
        key: 'name',
        name: 'Name',
      },
      {
        type: 'date',
        key: 'timestampStart',
        name: 'Date Start',
        min: moment().format('YYYY-MM-DD'),
        defaultValue: moment().format('YYYY-MM-DD'),

      },
      {
        type: 'time',
        key: 'timeStart',
        name: 'Time Start',
        defaultValue: '08:00',

      },
      {
        type: 'date',
        key: 'timestampEnd',
        name: 'Date End',
        min: moment().format('YYYY-MM-DD'),
        defaultValue: moment().add(5, 'd').format('YYYY-MM-DD'),
      },
      {
        type: 'time',
        key: 'timeEnd',
        name: 'Time End',
        defaultValue: '23:00',

      },

    ],
    rules: {
      name: [v => !!v || 'Name is required'],
    },
  },
  edit: true,
  delete: true,
}