import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [{
        text: i18n.t("model.prop.no"),
        value: 'index',
        sortable: false,
      },
        {
          text: "Name",
          value: 'name',
        },
        {
          text: "Key",
          value: 'key',
        },
        {
          text: "Seat",
          value: 'seat',
        },
        {
          text: "Sponsorship",
          value: 'sponsorshipCount',
        },
        {
          text: "Weight",
          value: 'weight',
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: "Key",
          value: 'key',
        },
        {
          text: "Weight",
          value: 'weight',
        },
        {
          text: "Seat",
          value: 'seat',
        },
        {
          text: "Amount",
          value: 'amount',
        },
        {
          text: "Plan",
          value: 'Plan',
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'string',
        key: 'name',
        name: 'Name',
      },
      {
        type: 'string',
        key: 'key',
        name: 'Key',
      },
      {
        type: 'model',
        key: 'planKey',
        name: 'Plan',
        model: 'plan',
        optionText: 'name',
        server:'sso',
        optionValue: 'key',
      },
      {
        type:'number',
        key:'weight',
        name:'Weight',
      },
      {
        type:'number',
        key:'seat',
        name:'Seat',
      },
      {
        type:'number',
        key:'amount',
        name:'Amount',
      },
    ],
    rules: {
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  edit: true,
  delete: true,
}