
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.name"),
          value: 'playerStudentName',
        },
        {
          text:  i18n.t("model.name.institution"),
          value: 'Institution',
        },
        {
          text:  i18n.t("model.name.classroom"),
          value: 'Classroom',
        },
      ],
    },
  },
  read:false,
  add:{
    fields:[
      {
        type:'string',
        key:'name',
        name:i18n.t("model.prop.name"),
      },
    ],
    rules:{
      name: [v => !!v || i18n.t("rules.name")],
    },
  },
  edit:true,
  delete:true,
}