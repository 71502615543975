import i18n from "@/plugins/i18n";
import moment from 'moment'

export default {
  browse: {
    table: {
      headers: [
        {
          text: "Sponsorship Package",
          value: "SponsorshipPackage",
        },
        {
          text: "Start At",
          value: "timestampStart",
        },
        {
          text: "End At",
          value: "timestampEnd",
        },
      ],
    },
  },
  read: {
    tabs: [
      // {
      //   key: "Institution",
      //   label: 'Institution',
      // },
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: "Sponsorship Package",
          value: "SponsorshipPackage",
        },
        {
          text: "Start At",
          value: "timestampStart",
        },
        {
          text: "End At",
          value: "timestampEnd",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'model',
        key: 'sponsorshipPackageKey',
        name: 'Sponsorship Package',
        model: 'sponsorshipPackage',
        optionText: 'name',
        server:'sso',
        optionValue: 'key',
      },
      {
        type: "date",
        key: "dateStart",
        name: "Date Start",
        min: moment().format("YYYY-MM-DD"),
        defaultValue: moment().format("YYYY-MM-DD"),
      },
      {
        type: "time",
        key: "timeStart",
        name: "Time Start",
        defaultValue: "08:00",
      },
      {
        type: "date",
        key: "dateEnd",
        name: "Date End",
        min: moment().format("YYYY-MM-DD"),
        defaultValue: moment().add(1, "years").format("YYYY-MM-DD"),
      },
      {
        type: "time",
        key: "timeEnd",
        name: "Time End",
        defaultValue: "23:00",
      },
    ],
    rules: {
      sponsorshipPackageKey: [(v) => !!v || 'Sponsorship Package Key required'],
    },
  },
  edit: true,
  delete: true,
};
