import Moderator from '@/models/bread/Moderator/institutionSponsor'

export default {
  key:'InstitutionSponsor',
  server:'sso',
  name:{
    singular:'InstitutionSponsor',
    plural:'InstitutionSponsors',
  },
  parents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}