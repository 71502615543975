
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.name"),
          value: 'Student',
        },
        {
          text:  i18n.t("model.name.institution"),
          value: 'Institution',
        },
        {
          text:  i18n.t("model.name.classroom"),
          value: 'Student.Classroom.name',
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:false,
}