import Moderator from '@/models/bread/Moderator/achievement'

export default {
  key:'Achievement',
  name:{
    singular:'Achievement',
    plural:'Achievements',
  },
  parents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}