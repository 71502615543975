import i18n from '@/plugins/i18n'
export default {
  browse: {
    table: {
      headers: [{
          text:i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Institution',
          value: 'Institution',

        },
      ],
    },
  },
 read: {
    tabs: [
      {
        key: 'Info',
        label:  i18n.t("string.info"),
      },

    ],
    table: {
      headers: [
        {
          text: 'Institution',
          value: 'Institution',

        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [{
        type: 'model',
        key: 'institutionId',
        name: 'Institution',
        model: 'institution',
        optionText: 'name',
        server: "sso",
        defaultValue: 1,
      },
    ],
    rules: {
      institutionId: [v => !!v || 'Institution is required'],
    },
  },
  edit: false,
  delete: true,
}