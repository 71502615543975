import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Key",
          value: "key",
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'AchievementLevel',
        label:  'Achievement Level',
      },
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
     
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Name",
          value: "translatableName",
        },
        {
          text: "Key",
          value: "key",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'translatableName',
        name:'Theme Name',
        defaultValue:'{"en":"Example","zh":"例子","ms":"Contoh"}'
      },
      {
        type:'string',
        key:'key',
        name:'Key',
      },
    ],
    rules:{
      translatableName: [v => !!v || 'Achievement name is required'],
      key: [v => !!v || 'Achievement key is required'],
    },
  },
  edit:true,
  delete: true,
};
