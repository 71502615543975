import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Mochi Id",
          value: "mochiId",
        },
        {
          text: "Avatar",
          value: "avatar",
        },
        {
          text: "Level",
          value: "level",
        },
        {
          text: "XP",
          value: "xp",
        },
      ],
    },
  },
  read: {
    tabs: [
      // {
      //   key:'Save',
      //   label:'saves',
      // },
      {
        key: "Student",
        label: i18n.t("model.name.student"),
      },
      {
        key: "Book",
        label: "Book",
      },
      {
        key: "Inventory",
        label: "Inventory",
      },
      {
        key: "Activity",
        label: "Activity",
      },
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
      // {
      //   key:'Performance',
      //   label:i18n.t("model.name.performance"),
      // },
    ],
    table: {
      headers: [
        {
          text: "Mochi Id",
          value: "mochiId",
        },
        {
          text:  "Enable PIN",
          value: 'enablePIN',
        },
        {
          text:  "PIN",
          value: 'PINNumber',
        },
        {
          text: "Level",
          value: "level",
        },
        {
          text: "XP",
          value: "xp",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "string",
        key: "mochiId",
        name: "Mochi Id",
      },
    ],
    rules: {
      mochiId: [
       (v) => !!v || "Mochi id is required",
       (v)=> v.length >= 15 && v.length <= 20|| "Mochi id has to be between 15 and 20",
      ],
    },
  },
  edit: true,
  delete: true,
};
