import Moderator from '@/models/bread/Moderator/sponsorshipSponsoredStudent'

export default {
  key:'SponsoredStudent',
  name:{
    singular:'SponsoredStudent',
    plural:'SponsoredStudents',
  },
  parents:[
    'Sponsorship',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}